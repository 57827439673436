import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import styled from "styled-components";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

const StyledImage = styled(GatsbyImage)`
  border: 0.3rem solid #cccccc;
`;

const Gallery = ({ images }) => {
  return (
    <SimpleReactLightbox>
      <Container fluid>
        <SRLWrapper>
          <Row className="justify-content-center">
            {images.map((image, index) => {
              return (
                <StyledCol md="auto" key={index}>
                  <a href={getSrc(getImage(image.fullsize))}>
                    <StyledImage image={getImage(image.thumbnail)} alt="" />
                  </a>
                </StyledCol>
              );
            })}
          </Row>
        </SRLWrapper>
      </Container>
    </SimpleReactLightbox>
  );
};

export default Gallery;
